import { CSSProperties } from 'styled-components';

import { BodilColor } from '@src/branding';
import { NorlysFont } from '@src/branding/font';
import { InputSize } from '@src/model/input';

import { inputWidth } from './common';

export const inputSizeStyleNorlys = (size: InputSize) => ({
  width: inputWidth(size),
  height: inputHeightNorlys(size),
});

export const labelStyleNorlys = () =>
  ({
    position: 'absolute',
    left: '11px',
    top: '7px',
    zIndex: 2,
    ...NorlysFont.style('default', 12),
    color: BodilColor.greyscale[10],
  }) as CSSProperties;

export const inputHeightNorlys = (size: InputSize) => {
  switch (size) {
    case 'small':
      return '42px';
    case 'large':
      return '58px';
    case 'middle':
      return '50px';
  }
};

export const numberInputPaddingNorlys = (size: InputSize) => {
  const height = inputHeightNorlys(size);
  switch (size) {
    case 'small':
      return `calc(${height} - 26px)`;
    case 'large':
      return `calc(${height} - 42px)`;
    case 'middle':
      return `calc(${height} - 34px)`;
  }
};

export const textInputPaddingNorlys = (size: InputSize) => {
  const height = inputHeightNorlys(size);
  switch (size) {
    case 'small':
      return `calc(${height} - 22px)`;
    case 'large':
      return `calc(${height} - 38px)`;
    case 'middle':
      return `calc(${height} - 30px)`;
  }
};
