import { InputSize } from '@src/model';

export const inputWidth = (size: InputSize) => {
  switch (size) {
    case 'small':
      return '144px';
    case 'large':
      return '360px';
    case 'middle':
    default:
      return '240px';
  }
};
