import { CSSProperties } from 'react';

import { InputSize } from '@src/model/input';

import { BodilColor } from '../color';
import { BodilFont } from '../font';
import { inputWidth } from './common';

export const inputHeight = (size: InputSize) => {
  switch (size) {
    case 'small':
      return '24px';
    case 'large':
      return '40px';
    case 'middle':
    default:
      return '32px';
  }
};

export const numberInputPaddingMobile = (size: InputSize) => {
  const height = '32px';
  const paddingLeft = '5px';

  switch (size) {
    case 'small':
      return { padding: `calc(${height} - 32px)`, paddingLeft };
    case 'large':
      return { padding: `calc(${height} - 24px)`, paddingLeft };
    case 'middle':
      return { padding: `calc(${height} - 28px)`, paddingLeft };
  }
};

export const textInputPaddingMobile = (size: InputSize) => {
  const height = '32px';
  const paddingLeft = '16px';

  switch (size) {
    case 'small':
      return { padding: `calc(${height} - 28px)`, paddingLeft };
    case 'large':
      return { padding: `calc(${height} - 20px)`, paddingLeft };
    case 'middle':
      return { padding: `calc(${height} - 24px)`, paddingLeft };
  }
};

export const inputHeightAddress = (size: InputSize) => {
  switch (size) {
    case 'small':
      return '32px';
    case 'large':
      return '48px';
    case 'middle':
    default:
      return '40px';
  }
};

export const inputAddressHouseSize = (size: InputSize) => {
  switch (size) {
    case 'small':
      return '12px';
    case 'large':
      return '28px';
    case 'middle':
    default:
      return '20px';
  }
};

export const inputSizeStyleAddress = (size: InputSize) => ({
  width: inputWidth(size),
  height: inputHeightAddress(size),
});

export const labelStyle = () =>
  ({
    position: 'absolute',
    left: '11px',
    top: '7px',
    zIndex: 2,
    ...BodilFont.style('default', 12),
    color: BodilColor.greyscale[10],
  }) as CSSProperties;
